import { useTranslation } from 'react-i18next'

function LanguageButton(props) {
    const { i18n } = useTranslation();

    return (
        <>
            <button onClick={() => i18n.changeLanguage(props.localisation)} className="languageButton">
                <img
                    src={props.logo}
                    width="20"
                    height="20"
                    alt={props.alt}
                />
            </button>
        </>
    );
}

export default LanguageButton;