import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name: { value: '', isValid: undefined, isTouched: false },
    email: { value: '', isValid: undefined, isTouched: false },
    textField: { value: '', isValid: undefined, isTouched: false },
    captcha: { value: '', isValid: false },
    agreeOnSchoolDetails: false,
    agreeOnNews: false,
    validated: false
};

export const contactUsSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {

        nameChange: (state, action) => {
            state.name = action.payload;
        },
        emailChange: (state, action) => {
            state.email = action.payload;
        },
        textFieldChange: (state, action) => {
            state.textField = action.payload;
        },
        captchaChange: (state, action) => {
            state.captcha = action.payload;
        },
        agreeOnSchoolDetailsChange: (state, action) => {
            state.agreeOnSchoolDetails = action.payload;
        },
        agreeOnNewsChange: (state, action) => {
            state.agreeOnNews = action.payload;
        },
        validatedChange: (state, action) => {
            state.validated = action.payload;
        },
        resetFormState: (state) => {
            state = initialState;
        }
    },
})

// Action creators are generated for each case reducer function
export const { nameChange, emailChange, textFieldChange, captchaChange, agreeOnNewsChange, agreeOnSchoolDetailsChange, validatedChange, resetFormState } = contactUsSlice.actions

export default contactUsSlice.reducer