import React from 'react';
import { Image } from 'react-bootstrap';
import bannerImage from '../images/banners/banner.jpg';

const Banner = () => {
  const handleClick = () => {
    window.location.href = 'https://donate.stmarysukrschool.co.uk/';
  }

  return (
    <div onClick={handleClick} style={{cursor: 'pointer', width: '100vw'}}>
      <Image src={bannerImage} style={{width: '100%'}} fluid />
    </div>
  );
}

export default Banner;