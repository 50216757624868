import { Modal } from 'react-bootstrap';

function NewsLetterModal({pdfUrl, name, showBrochure, setShowBrochure}) {
    return (
        <>
            <Modal show={showBrochure} fullscreen={true} onHide={() => setShowBrochure(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body><object data={pdfUrl} type="application/pdf" width="100%" height="100%"></object></Modal.Body>
            </Modal>
        </>
    );
}

export default NewsLetterModal;