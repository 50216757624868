import MainBlockLayout from '../../components/MainBlockLayout';
import React, { useRef, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NewsTicker, { Directions } from "react-advanced-news-ticker";
import { Trans } from 'react-i18next';
import arrowUp from '../../images/arrow_cloud_up_upload_icon.svg';
import arrowDown from '../../images/arrow_down_download_save_icon.svg';
import ImageGallery from 'react-image-gallery';
import ClockLoader from "react-spinners/ClockLoader";
import { useSelector } from 'react-redux';

function MainBlock() {
    const ref = useRef(null);
    const [show, setShow] = useState(false);
    const [showModalINformation, setShowModalINformation] = useState('0');
    const newsList = useSelector(state => state.news.news);

    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#feda00");

    let newsObject = newsList;

    function handleModal(modalName) {
        setShowModalINformation(modalName)
        setShow(true)
    }

    function generateNews() {
        let arrayOfNews = [];

        for (let key in newsObject) {
            arrayOfNews.push(
                <>
                    <Col>
                        <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxHeight: '20%' }}>
                            <Trans i18nKey={newsObject[key].preview}></Trans>
                        </p>
                        <span style={{ fontSize: '12px' }}>{newsObject[key].date}</span><br />
                        <Button variant="light" onClick={() => handleModal(key)} style={{ backgroundColor: '#81b0e0' }}>
                            <Trans i18nKey="Read more.."></Trans>
                        </Button>
                    </Col>
                </>
            )
        }

        return arrayOfNews;
    }

    let imagesForSlider = (images) => images.map((image) => {
        return {
            original: image
        }
    });

    return (
        <>
            <Modal show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w calibriFontFamily"
                size="xl"
                onShow={() => setLoading(true)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans i18nKey={newsObject[showModalINformation].header}></Trans><br />
                        <span style={{ fontSize: '12px' }}>{newsObject[showModalINformation].date}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <Trans i18nKey={newsObject[showModalINformation].body}></Trans>
                    </p>
                    <Row style={{ marginTop: '35px' }}>
                        <div style={{ display: loading ? "none" : "block" }}>
                            <ImageGallery items={imagesForSlider(newsObject[showModalINformation].photos)} lazyLoad={true}
                                showPlayButton={false} showFullscreenButton={false} onImageLoad={() => setLoading(false)}
                                autoPlay={false} slideInterval={8000} />
                        </div>
                        <div style={{ display: 'flex', webkitBoxPack: 'center', placeContent: 'center', placeItems: 'center', webkitBoxAlign: 'center' }}>
                            <ClockLoader
                                color={color}
                                loading={loading}
                                size={100}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>


            <Row>
                <img src={arrowUp}
                    width="30px"
                    height="30px"
                    onClick={() => {
                        ref.current.movePrev();
                        ref.current.resetInterval();
                    }}
                    alt="Arrow Up"
                />
                <NewsTicker ref={ref} id="ticker" direction={Directions.UP} rowHeight={120} maxRows={6} duration={5000} autoStart={true}>
                    {generateNews()}
                </NewsTicker>
                <img src={arrowDown}
                    width="30px"
                    height="30px"
                    onClick={() => {
                        ref.current.moveNext();
                        ref.current.resetInterval();
                    }}
                    alt="Arrow Down"
                />
            </Row>

        </>
    )
}

export default MainBlockLayout(MainBlock);