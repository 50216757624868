export const IMAGES_FOR_SLIDER = {
    slider1: {
        url: "https://dl.dropboxusercontent.com/s/b6047qyrtlhfnqj/slide1.jpeg?dl=0"
    },

    slider2: {
        url: "https://dl.dropboxusercontent.com/s/dwg4mbue8783z55/slide2.jpeg?dl=0"
    },

    slider3: {
        url: "https://dl.dropboxusercontent.com/s/bvtr0ni7268sv2x/slide3.jpeg?dl=0"
    },

    slider4: {
        url: "https://dl.dropboxusercontent.com/s/kp0n2ivx86lwl9k/slide4.jpeg?dl=0"
    },

    slider5: {
        url: "https://dl.dropboxusercontent.com/s/eusxx7le3xatzxy/slide5.jpeg?dl=0"
    }
}

export const OUR_TEAM = "https://lh3.googleusercontent.com/R6rdXUvLK8zfvkPpgJ14W5dbugL9v0FXls3bPcSic4hNzskXsC86QJE6T9LtdTUdkziNCO6-f-oioeQEJx-afJCBrfNfpXYYB1uCwTA-yMRH3TpJYIadAGagOhBD_1Gz6HSZbmJXu8L4e4zCc7SWIobaAa_zf_79KPdQEL5ku2xP9PJhx1QZaxqmNBKCmMO2z0QPUJAqgowXEXTNrYCW8Hu_jsizeefy4EDPdnqGty6b2IdJQrmZs5BOrUXmY3qQf8ujhVyLU77nmv2ISjnzzCnavxsLtrzSgkmwrrVXxJCS6uQjV48xN4TaTOOosBTnXX1LXc6KPr6Pz9nL1QHjbQCnxHSBAXydaUXBoqIsrzTEaHtrq778FHR8pbxkiWS4WGalrYLGFatNu1Uag8EDmlre03eF70oRNIq74btBWnduL-hWLYEMxOVv8KopAooh3-MlRXYrN_p6GawVKZdPEKVUujkrTQY93Bb8xnX_hoORUBwd7c4nLCApeLFDNacKLPfR0dVXKNlDtLfHlxcTXeB939p3RhU0cvk086uQY5I4C7pyY1bjuj5TXlW9n4uHJoMYpwinOR3jbjgD6Y22aWAkOGpDEzUc5FbzLPkFMTHrLh0ijfR3fMbO7OxkmPSjc5zExYtUJnu8NCMDYOk3VQzxn56qVQm-wmCE2QhIAHZovd1T_ui1CvY3WwwkQEBStisJ2JvmCqUGUu3juJzyJOjp3dHqHC_3fMhLY1IObpdluGLMw8wF1ctBzkJ2t_KMzhk73L9Je8YyNP9NHuu6ECc7xfNGJCD2tdnzTtmLwCL5gB3Zlq0IO8HIEbRPCGYKYkRTvAatQhBHcWitlQ8dhPW9VP-UL-HuN_UC1acveqvFVr8ay20GqQUnsaYUmISNMd6BiNcpsOL_o8_vFAyfRl0BmXLuwyUj_LpNd2DWOcdj8lTf2z2AdNTX-yT6pVOmtiNEpjXkvIRoijzY5g=w1200-h323-s-no"

export const INNA_PHOTO = "https://dl.dropboxusercontent.com/s/z5svblqwkc6jjof/Inna.jpeg?dl=0";

export const OUR_HISTORY = "https://lh3.googleusercontent.com/KibAAsM1FZeURoB8flnVkafmlGW65sqzoTpgCa0oakTfVtV41w2lr-8GW5nPgqNAdiUeqHLhO-at0ZJARn-UqyWwBNIDJcvyThKRuAB7BLeTj0IhldOVVOa399pyQ9nSdPmFnEz3qp3l51SuovCN_0HD5igJweI07qF4eMjwa9w-6fq_TBnAl6TprjW3BVrqTVPZ7_bSct4-uTcD8g-C3iwMqs0g2mkBhAlgVRC2gNL0fku1SSCZ90O8W0wu1Cr2iqWZHVrXkmlW5MMFa0e2i0Z7pACy4TGSX9rZdHLV5U6gvtCFAPr3a3ILYbz6vCQlnaAFLRISJx62mqXVfNq66ssFpAIOtrLpW5TkqzDia1nMnPDvYDE2Mcq7UzDn1zWk6jYBrU3jE-URFZg6rGy2TxA1dnqq9djl6DpXdgdFVKcAUlmyvVYSj0w5KREqAA5FvBWEI_P5Ncx_HsQkYrPwcrvOnz8GFljfbgdaukS5IpJWFRshX1EfILUXlCv-dRO145PddmZGKfliizGqY0GGhiANQfbLjrKyz-Ore1_wKV2DXxd05OQKfbbL-3Ph01SSqYaUWVpyyV-EfCFOkfhKGTSuMXxDGwTdCC6QkwtlI0zgBE6TfLci3vSBFnChhydX1b5cPjziyOa8BmKYHI2v35lqEP1CTaiLfBMDU_RpYCqwGJcu1ldyk4Z8lJgIxvNGEIesAYUx6UWunC9kp_Vi5ieN4mQ-WH4hRbi2vUbaQlaCN-SJzIu1j6V9Ho5thCESeN0hAiS9nwmvYZUUsqbMLOkUmboVKoZKnKLGgv7cJWiSFpxHKjD4zzLs9WtSIKXWrYSDE5k9y35AFn-jEqYiR6ceRX4ROnS8LqNIi0_bewYITlJHoS9x3IAvmTZ488qEOMZmUDMqwbpan7DwhQOMJ8auLDfPh5D44i0Vo35fxQe9JixXrKOuSBlnLu579L1cQlpRUYaGBYr6Yz-Osw=w1200-h323-s-no";

export const OUR_HISTORY_PAGE_IMAGES = {
    end_of_school: "https://dl.dropboxusercontent.com/s/h0v2jd8fxd7dwm5/End-of-School-Year-1967-1968.jpeg?dl=0",
    old_logo: "https://dl.dropboxusercontent.com/s/1kcm3u5v5u6n9cf/old-logo.jpeg?dl=0",
    mother_day: "https://dl.dropboxusercontent.com/s/ckw41jsuyaietnn/Mothers-Day-celebration-1974-1975.jpeg?dl=0",
    mychailo: "https://dl.dropboxusercontent.com/s/5fuuel636svzwab/Mychajlo-Szynkaruk---Head-Teacher-at-St.-Marys-Ukrainian-School-1965-2001.jpeg?dl=0",
    old_team: "https://dl.dropboxusercontent.com/s/bq2vuxzjo8qky99/School-students-1982---1992.jpeg?dl=0"
}

export const NEWS_IMAGES = {
    delegation_from_ukr: ["https://dl.dropboxusercontent.com/scl/fi/33unfxpns1m6vwbh9oohz/347580630_9903060469718996_4144159615370358064_n.jpg?rlkey=o60j7chxoat1mfryqzmyomy7a&dl=0", "https://dl.dropboxusercontent.com/scl/fi/ouz213ghgp85oty0odks1/347416523_9903059386385771_5333236863720396698_n.jpg?rlkey=ckawlb7adsplcdho6cnozqehg&dl=0", "https://dl.dropboxusercontent.com/scl/fi/f43dnse61nnoylke3qd4m/347416436_9903297709695272_199680480564114369_n.jpg?rlkey=n9o1g1fm4u47lsfbxic5dkutz&dl=0", "https://dl.dropboxusercontent.com/scl/fi/82trul45xapsnof09jtvo/347413246_9903064023051974_7972581803151073502_n.jpg?rlkey=vx927xoijfzf3ihqasd967oho&dl=0", "https://dl.dropboxusercontent.com/scl/fi/hcydq797337j04atof6yy/347409450_9903064796385230_4608675563876829956_n.jpg?rlkey=suq13mr2gmm5mj1wv7ilhe506&dl=0"],
    sports_day: ["https://dl.dropboxusercontent.com/scl/fi/rrx34g3dgr4p2lm6ppy2r/356667311_3364860273827447_4750751650474769194_n.jpg?rlkey=4go65q6h1jehkznmfhr49zc5u&dl=0", "https://dl.dropboxusercontent.com/scl/fi/8n4gokbvndutqo2fy8p32/356662247_3364859980494143_9058053832130664927_n.jpg?rlkey=wm5f4ejnr18jgf6ybpqdtv3vj&dl=0", "https://dl.dropboxusercontent.com/scl/fi/tc1oulqssmzxzb5g3b0iy/356659719_3364860140494127_631641050452773100_n.jpg?rlkey=f1pxx9tfyl0yjgwu46akp36wl&dl=0", "https://dl.dropboxusercontent.com/scl/fi/l9jaa3iwhd9ofyk1hovg0/356647530_3364859720494169_8375957518178101948_n.jpg?rlkey=mnpm908f1l5tsh3o4gmzo9uqm&dl=0", "https://dl.dropboxusercontent.com/scl/fi/rdot2m084d77zm5vrg0cj/356641014_3364859690494172_6314804857688909388_n.jpg?rlkey=c5qrq7cv0dpkw0itenyey1mxf&dl=0", "https://dl.dropboxusercontent.com/scl/fi/0qncd1ub666eijju6ae47/356630866_3364860040494137_3693971094384095639_n.jpg?rlkey=9ic6fw4nesc7ajmkfritpdo40&dl=0", "https://dl.dropboxusercontent.com/scl/fi/yugv3g4wqm8ohavax1kig/356617730_3364860217160786_913466359383430084_n.jpg?rlkey=jpahx8rocxj3t3lec2bauuwyj&dl=0", "https://dl.dropboxusercontent.com/scl/fi/kvef7b2pkjx7q2pxm6of7/356417126_3364859660494175_2855419901274850554_n.jpg?rlkey=14nq9gwn74vldasm4kc4h398h&dl=0"],
    award_mbe: ["https://dl.dropboxusercontent.com/s/g13sjfqmg0g93l7/355122331_9848610835163960_5589666164448638797_n.jpeg?dl=0"],
    award_2023: ['https://dl.dropboxusercontent.com/s/nhv31kazy32lltf/339635831_163017643349331_6684385101365542733_n.jpeg?dl=0'],
    ukraine_team: ['https://dl.dropboxusercontent.com/s/mch1oiys88e5ey8/337157321_3902993309926891_2495463632954079102_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/ac3jumlu183deds/337139267_6404901416187257_201477810956099072_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/6w90do15kw2f8st/337127442_1176748522988468_6565377839351484321_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/oto5ck8prmmcdfw/337123159_223399296900177_7901229859904491511_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/yjzqg47rk87k3ye/337099957_613279360243512_2562277229404193600_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/6z81gges5rai3k5/337015479_734088651603041_607324106534985108_n.jpeg?dl=0'], 
    summit_2023: ['https://dl.dropboxusercontent.com/s/kbjst9g9hzd0tiz/336008903_1003578330604709_5378713004891456184_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/wlnaynl3naz02gh/335248111_1430182401061795_2438820500209115471_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/79ab3gb7pen55p7/335186875_1310825872802315_2701378474788256062_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/sa0q9xayc7sfmix/335058135_1178283949503722_8299592775587256720_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/28trvjprr6936pc/335049154_1887429784955061_8773230879887867640_n.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/wnoyqirtfjyz9sl/335041142_241123518344834_7228364484971495876_n.jpeg?dl=0'],
    choir: ['https://dl.dropboxusercontent.com/s/8s0ap8fpq112sb5/photo_1.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/0lu70aw584xnlgd/photo_2.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/npvcg1hz3mh0cns/photo_3.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/fj7ad810bzh0bls/photo_4.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/9cnogsdcbio26k4/photo_5.jpeg?dl=0'],
    deni_sobornosti: ['https://dl.dropboxusercontent.com/s/01lex7497w1o9rg/photo_1.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/jikaqcsjb3rsv3a/photo_2.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/eea2dtaukftqvm3/photo_3.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/u95o7u3sgm5jiqb/photo_4.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/kpr0abxh3j0zug0/photo_5.jpeg?dl=0'],
    prostir: ['https://dl.dropboxusercontent.com/s/1cgjx4t4yza1ofb/photo_5.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/jxkcta6dsdmms5f/photo_6.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/vonyvl4y8lsgpoh/photo_7.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/5vhsu08bc6zabky/photo_8.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/fkzlrhfedtx438t/photo_9.jpeg?dl=0'],
    royal_foundation: ['https://dl.dropboxusercontent.com/s/pplng0xgxnyc5s2/photo_1.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/8b692chkyjqdvpr/photo_2.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/jm389h6ak5uv3pp/photo_3.jpeg?dl=0'],
    school_woking: ['https://dl.dropboxusercontent.com/s/jotgq4wxyvx1oml/photo_1.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/bi6vg8wh79mnv8l/photo_2.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/9lk0e3bfaknmv3h/photo_3.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/cgmazbymrzj86eg/photo_4.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/e82nocy5tl5zhng/photo_5.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/f3k5a5ink5ciar1/photo_6.jpeg?dl=0'],
    first_news: ['https://dl.dropboxusercontent.com/s/9b9f92wba6716bd/photo_1.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/7d5xy3a144lx8bv/photo_2.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/10s52pfdzkv77wj/photo_3.jpeg?dl=0', 'https://dl.dropboxusercontent.com/s/t1lx90qtpc60a1g/photo_4.jpeg?dl=0']
}

export const YEAR_2017 = ["https://dl.dropboxusercontent.com/s/l11kqmnwgy9cyx4/img-5899350960EE-B775-D8C9-3D00-53BAAD6C914B.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/wwos9sm6k45phr2/img-508846002A83-8F29-2943-A3A9-29862554F5DF.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/y3ayh2evf3ycfkz/img-53143565FA1B-2B01-DF6F-645E-52FA150FD6F2.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/5l5pqrga8igpmk1/img-52912259A29F-4781-B036-E882-44FC68A0A30F.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/56v5gp0zbs8lmrp/img-52856554AC68-0DAA-BD7E-CED7-CAB615BA8405.jpeg?dl=0",
    "https://dl.dropboxusercontent.com/s/ofgu0va2rr0zuor/img-50611079ECBA-26D2-A308-A278-3034986C7AF1.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/o5qqqbq9kcwhe4c/img-50510621E548-6286-51C7-3F35-F36747BEC593.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/75mzs5nl3xe8hs2/img-595057E21ABA-2106-4F81-7CFB-433507F1B9EE.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/v7yrlnkxuzpexgo/img-533930EA90C1-0649-CC34-424F-CE70FE6A0117.jpeg?dl=0",
    "https://dl.dropboxusercontent.com/s/uchejfogflmy9zo/img-530297E187FE-5CF8-ECA3-D8A9-1A3D6DA7447A.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/v6b2ecyeedlygpg/img-528899C083F1-8914-AF6A-80AA-0B3D3715527B.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/5hbrhroep1xpkn6/img-59047DA9FAFD-CD80-B2F6-F0D9-AAA27D50E12F.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/i08jcj1pvlfbftx/img-53222E739D51-5A25-7B0A-571A-CB391C565A81.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/yqfquj2yo34rwuo/img-52811A1904CB-D65A-0739-F111-5CA121E76876.jpeg?dl=0",
    "https://dl.dropboxusercontent.com/s/pom7lx1t4jhzdk1/img-52723B40A59F-F099-CA85-591B-6F7F9E678BE3.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/ko050mo32tppvj3/img-52610E3D7125-7692-D236-22C5-9B7A2F6BD153.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/vwhyxqgnqx3tw0w/img-50547D010FBC-7AB1-DC41-E15D-42ABD188B072.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/3s81axll1a9jqob/img-50412A2D3FF7-7F5E-61D0-D3D2-AF97E5F48CB2.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/awpd26m18c5g4fw/img-5937B6B7FCD2-9409-0437-1B53-864742BB9F92.jpeg?dl=0",
    "https://dl.dropboxusercontent.com/s/14f9j0oz6rlu1eq/img-5926DA40E78C-38F1-7615-C155-E8A9A0F4D39B.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/11o72w213q774u7/img-5920DF381041-208E-BF88-B62D-20967956A08D.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/rnyn2q06r7pli41/img-5917CF6F21B6-2B06-ACA1-B499-05D2F773C1C2.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/l5yxowfueeq8b4o/img-5915EBB6E3D7-5BF1-950E-7B08-FE59FF723A33.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/5b6zvl9othx0c1l/img-5901A1B0836C-82DF-0CD8-7E8F-4BECED683F48.jpeg?dl=0"];

export const YEAR_2018 = ["https://dl.dropboxusercontent.com/s/f7qbht5z86e7euo/49069705-10214620962691039-4420521894575341568-o80527D3C-BE30-0952-BADA-1E3EB1191ABE.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/3saguv84g8xhzbh/49048612-10214620984211577-3273531610955251712-oB860B5AF-B0E7-3279-1F2E-DE99C754C15F.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/mph8etbm7ystr8a/49014548-10214620990291729-7860139804967567360-o0339564E-6941-A6B6-CA9B-032CCF70F5FF.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/d9gg5b7jbofqzpb/48430027-10214620959970971-5758382049543061504-oE7F85EDF-1520-86B1-9791-19B2393FD299.jpeg?dl=0",
    "https://dl.dropboxusercontent.com/s/vqp02merixqza3t/48428727-10214620957570911-414251532729450496-oD2D476CE-D0BB-CBB8-96E0-DDF87240ED3B.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/7jw6s4i03fry18g/48417998-10214621025252603-8483718994207965184-o80023986-D499-14BE-D1A3-CBFC2BE48471.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/y8opw8k8kjkd21y/48413173-10214620957490909-7114524931102081024-o8C0BFF06-EB72-05B0-0A56-933CF782EB3B.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/as728np50tiovbz/48403527-10214620993011797-7536230744645959680-o7D63EB24-1801-8131-8EA0-BD29FFE7BF4D.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/14ykfgr71mgfv4k/48398200-10214620998531935-1169465791645483008-oB519A631-CCA0-2D89-EEC8-0970C5662246.jpeg?dl=0",
    "https://dl.dropboxusercontent.com/s/qrfw57quancgncs/48397315-10214620977611412-337129111392616448-o8F804503-52C9-5B7F-10B5-BBDA1D46BA76.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/0ivofb0x472us2l/48396099-10214621001011997-3612357620976844800-o81EFC42C-9CBB-D329-B975-B754FF12E64B.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/vn9f9skpjv2r5g8/48393747-10214620991571761-7287412616194949120-o33A9F6BC-A57F-2E13-171D-5A6292E0C06A.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/d7rbivnoa6gveb1/48384895-10214620999931970-5270390476711657472-o0DBF69F4-954E-45B2-E6BA-B55F43CB4567.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/2zumny6w4sfqs1v/46831518-10215375061782610-5512148709720195072-n89301B69-83BD-1996-0738-CD89E24555B8.jpeg?dl=0",
    "https://dl.dropboxusercontent.com/s/o6ngcr1ctc3j1l7/46750696-10215375067422751-8139919540906622976-nD864BA14-1430-508D-E7AF-095DC7181073.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/yafmc7aqeez87r6/46659763-10215375075542954-8176052439342907392-nDC8A426B-5BDD-752E-64F9-61434D70F137.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/9wme3bmbxqbcjva/46525676-10215375074662932-7722066927207579648-n1E052C9F-962A-33B9-DB8D-0073D4947CE9.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/0rtnjab95tsjk4v/46521512-10215375062142619-3219330145881948160-nAE3BE55E-F0E3-EACE-F8BB-E5C3049A2705.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/w1ubtq9fgzifass/0149B33C10B-2D43-6A65-D8FC-C17F2749E232.jpeg?dl=0"];

export const YEAR_2019 = ["https://dl.dropboxusercontent.com/s/q8glify9fzyigyq/8E3974FE8-533E-7B89-7511-7B983934D227.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/aiu11kwhebf556m/72157222-10217044313193287-1556813258390765568-o04E068FE-28BA-3FF3-B596-0DEE957F5CF5.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/3n201ffdlid4y6e/74209240-10217044304633073-670020550352961536-oC5FC0BB5-028C-968E-D0D1-8BA1C165793B.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/w5f6jlbz3witgus/74229520-10217859858940986-2313114358781050880-n349D5A69-61B1-3B5A-3D5A-7FBA70F36EB3.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/cg8qwmsy3r1tyd3/74269391-10217859859621003-4495663648793952256-n81855964-4FB9-4E3A-5F1A-A1038DA079C5.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/epexcrb86p2lkae/74274586-10217044255911855-1683614922195337216-o7F771F08-2B30-4AC6-5F04-4C7B2ABCDF2B.jpeg?dl=0",
"https://dl.dropboxusercontent.com/s/nix1whtj132lp9a/74399577-10217044445516595-3485970696394244096-oEBC2F41B-A2C1-0BBE-2F21-5B35D295AD54.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/o4tet47guo3egbs/74578557-10217044243991557-1598972189216866304-oAB0EAC3B-57C5-FA69-7567-47DAB759C2B3.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/83ysgnk4l4ehwun/74701531-10217044343954056-287598118311034880-o2252AFC8-34DA-5696-AA3C-C0076E35AE23.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/t2jgz167c78erry/75120119-10217859859781007-1984941594230390784-nF9AC0641-5E3A-FD64-E93E-D98D9346A280.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/z9to6ixd0uw2u32/75241191-10217044436076359-7434573653343207424-oFECDECDD-E223-9126-73AB-E0A046AFB411.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/3mhj9f42e1h0dns/75262071-10217044472957281-3592652800649068544-oACD0ABD0-F391-82E7-E3F9-FAA97D22DF72.jpeg?dl=0",
"https://dl.dropboxusercontent.com/s/8xglmep78zub8ft/75305229-10217044268832178-3122569278641930240-oA4D9159E-3A83-EF7B-F01F-F5A6DE341E88.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/8vh597lgucvk84y/75356939-1416044928560217-7923203058882838528-nF5412C46-9C09-EC31-9398-1CC9DA04A27D.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/4401jnm6zbcs4v5/76722747-1416044278560282-920229058501935104-nDCE183BD-CE00-1E4E-DE0F-1DCBDA6AEDF5.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/u19agpmz80ea29k/76751456-10217859861661054-3391148873744384000-nF92D69D1-D398-3F14-F120-B597C4D1EF12.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/sv35ykvbrbwcxag/76771607-1416044801893563-351036640698826752-n70307684-DFB8-6FE2-4889-8FA2E67E4818.jpeg?dl=0", 
"https://dl.dropboxusercontent.com/s/kyf456p1n9b8zsv/76897500-1416045155226861-3799752855192076288-nCCDB2A71-2D50-13F8-D964-418941B73FC3.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/9zgj33db02gn2ek/77191919-10217859860301020-3131468072922120192-nEBCCFE3B-2476-3B65-36A8-286F068CCD4D.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/xgpshr6zp7rh1on/77280126-1416044981893545-8409155192263540736-n2D74A464-4433-8AF6-5B23-A1086E4B661B.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/84l34918kwzcrh4/77288128-1416045101893533-235948727099981824-n9F39B33E-7DCC-1D75-03D9-AA0F5DB49BC8.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/o5xtyrrjkbdw8du/77350824-1416044271893616-2002762693441748992-nB226533F-2676-2236-5631-D966C2DD911A.jpeg?dl=0", 
"https://dl.dropboxusercontent.com/s/96auz6tv5aiacdg/77398608-1416044751893568-5840570651093499904-n489601B9-B8D3-04F6-459D-1D4A72F4D088.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/v5ajjfey2gemqh3/77401450-1416044661893577-8885130829580206080-nF8EBC74E-842E-D074-7AEB-CD7B772F6F2C.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/w9coid4104ax8gd/78130408-1416044475226929-2680780558799208448-n03C910AE-49E1-AF6C-21ED-C88A7E5EAEB2.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/d4d0n48qtrvr7ra/78717911-1416045355226841-7994723623094976512-n6CAD52D8-09DD-D856-A1D1-758B1AF2702E.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/z6muifx89um58yi/78809596-1416044551893588-158637048029249536-n49CB205F-9C63-420B-9FD8-49ECAA13D9DB.jpeg?dl=0", 
"https://dl.dropboxusercontent.com/s/j29kryz8pde48y6/78841371-1416044451893598-7282941095678312448-n00EF6FD9-73DD-708A-55EE-6366B743B88A.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/l2729t2pzvxwdl8/78948638-1416044365226940-7238702597522063360-n79BCC69E-BDD8-95C3-A634-A825DCC5F101.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/aqid37cdapumnon/img-9022BFDA3EE0-992B-6BA8-12B6-F23A6B9DF710.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/f3hcku8rwmv2fnh/img-9052B4229FC3-E3AC-F24A-D4EE-DDFE9E9ADE5E.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/jznxj8b79fy4bpd/img-9055A802CF5A-5C25-ED91-B156-181AB797DFE3.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/v6ady8hm4csfv93/img-9057D3FD603E-5BD4-CCC0-F9F1-6D696D9101D7.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/v53k8o84h2cs4w9/img-9067F7EB9B28-D861-40AF-1BF0-57CB00C89619.jpeg?dl=0",
"https://dl.dropboxusercontent.com/s/lxampdtafbwoh65/img-9082F7F5C022-BBD6-36CC-470F-5567DFB7F8C1.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/hx19mokhklkz3gy/img-90493AEAF1E7-428E-EBA1-A534-2CE72DCEE97E.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/rzs2wpwkv0ratbc/img-90598C53E92F-AC89-068C-84F1-ECBB2591E0F9.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/57e8vhhcaq9uhh6/img-91046A3A199D-9321-E852-13F1-0CBD325C5BBA.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/n2y7iw00durfkdi/img-904754FA3E7C-872B-D30B-77A9-14CD73A5248B.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/2kz3r20ji3e428u/img-906619EAC0E3-BBC7-C176-2DC3-C9AB349682D7.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/pzlc1zea8svrg2r/img-915649A28921-C537-6CB5-D528-D2D539C80537.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/ijgl07xyhotrj7s/img-917914A2D55B-98E6-C3A2-352E-CE3B8DF4B4B8.jpeg?dl=0",
"https://dl.dropboxusercontent.com/s/3a27rao9kph61m7/img-9011329DEB93-3ECF-B99B-A405-5E5AB2C46F0D.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/38x39x9j11if3j8/img-9068664F1670-053F-94B1-183F-BD9C6A2777BE.jpeg?dl=0", "https://dl.dropboxusercontent.com/s/e81ttswd5es1ixf/img-9089134548C4-F44C-22F3-1B70-D51365B15B5E.jpeg?dl=0"];

export const YEAR_2020 = ["https://dl.dropboxusercontent.com/s/zd5cq2o8bczya5a/image0D6E225D4-8914-1C62-A723-B17FB712C3E9.jpeg?dl=0"]

export const YEAR_2022 = [];

export const YEAR_2023 = [
"https://dl.dropboxusercontent.com/scl/fi/33unfxpns1m6vwbh9oohz/347580630_9903060469718996_4144159615370358064_n.jpg?rlkey=o60j7chxoat1mfryqzmyomy7a&dl=0", "https://dl.dropboxusercontent.com/scl/fi/ouz213ghgp85oty0odks1/347416523_9903059386385771_5333236863720396698_n.jpg?rlkey=ckawlb7adsplcdho6cnozqehg&dl=0", "https://dl.dropboxusercontent.com/scl/fi/f43dnse61nnoylke3qd4m/347416436_9903297709695272_199680480564114369_n.jpg?rlkey=n9o1g1fm4u47lsfbxic5dkutz&dl=0", "https://dl.dropboxusercontent.com/scl/fi/82trul45xapsnof09jtvo/347413246_9903064023051974_7972581803151073502_n.jpg?rlkey=vx927xoijfzf3ihqasd967oho&dl=0", "https://dl.dropboxusercontent.com/scl/fi/hcydq797337j04atof6yy/347409450_9903064796385230_4608675563876829956_n.jpg?rlkey=suq13mr2gmm5mj1wv7ilhe506&dl=0",    
"https://dl.dropboxusercontent.com/scl/fi/rrx34g3dgr4p2lm6ppy2r/356667311_3364860273827447_4750751650474769194_n.jpg?rlkey=4go65q6h1jehkznmfhr49zc5u&dl=0", "https://dl.dropboxusercontent.com/scl/fi/8n4gokbvndutqo2fy8p32/356662247_3364859980494143_9058053832130664927_n.jpg?rlkey=wm5f4ejnr18jgf6ybpqdtv3vj&dl=0", "https://dl.dropboxusercontent.com/scl/fi/tc1oulqssmzxzb5g3b0iy/356659719_3364860140494127_631641050452773100_n.jpg?rlkey=f1pxx9tfyl0yjgwu46akp36wl&dl=0", "https://dl.dropboxusercontent.com/scl/fi/l9jaa3iwhd9ofyk1hovg0/356647530_3364859720494169_8375957518178101948_n.jpg?rlkey=mnpm908f1l5tsh3o4gmzo9uqm&dl=0", "https://dl.dropboxusercontent.com/scl/fi/rdot2m084d77zm5vrg0cj/356641014_3364859690494172_6314804857688909388_n.jpg?rlkey=c5qrq7cv0dpkw0itenyey1mxf&dl=0", "https://dl.dropboxusercontent.com/scl/fi/0qncd1ub666eijju6ae47/356630866_3364860040494137_3693971094384095639_n.jpg?rlkey=9ic6fw4nesc7ajmkfritpdo40&dl=0", "https://dl.dropboxusercontent.com/scl/fi/yugv3g4wqm8ohavax1kig/356617730_3364860217160786_913466359383430084_n.jpg?rlkey=jpahx8rocxj3t3lec2bauuwyj&dl=0", "https://dl.dropboxusercontent.com/scl/fi/kvef7b2pkjx7q2pxm6of7/356417126_3364859660494175_2855419901274850554_n.jpg?rlkey=14nq9gwn74vldasm4kc4h398h&dl=0",    
"https://dl.dropboxusercontent.com/s/ep1sik18o392f3o/FB_IMG_1683727208479.jpg?dl=0", "https://dl.dropboxusercontent.com/s/yt6p2yzwngqm7o4/FB_IMG_1683727205000.jpg?dl=0", "https://dl.dropboxusercontent.com/s/nx9c5q2zde7o243/FB_IMG_1683727200353.jpg?dl=0", "https://dl.dropboxusercontent.com/s/j9v0cghd1s5001t/FB_IMG_1683727193795.jpg?dl=0", "https://dl.dropboxusercontent.com/s/gxni8g9pxmsj1eq/FB_IMG_1683727184698.jpg?dl=0", "https://dl.dropboxusercontent.com/s/dq959ga1wbbhlza/FB_IMG_1683727167701.jpg?dl=0", "https://dl.dropboxusercontent.com/s/owuua4jbbo56dmz/FB_IMG_1683727134023.jpg?dl=0", "https://dl.dropboxusercontent.com/s/uadvusmrj4igbuf/FB_IMG_1683727125700.jpg?dl=0", "https://dl.dropboxusercontent.com/s/n95vo1b8xduz46a/FB_IMG_1683727115168.jpg?dl=0", "https://dl.dropboxusercontent.com/s/6ltji7luwo38m4u/FB_IMG_1683727108371.jpg?dl=0",
"https://dl.dropboxusercontent.com/s/upx1efv1ryo8o1l/IMG-20230326-WA0023.jpg?dl=0", "https://dl.dropboxusercontent.com/s/i3lvebu66pozy3s/IMG-20230326-WA0022.jpg?dl=0", "https://dl.dropboxusercontent.com/s/ktvainvxnxo91o6/IMG-20230326-WA0021.jpg?dl=0", "https://dl.dropboxusercontent.com/s/db97auzuac1sgad/IMG-20230326-WA0020.jpg?dl=0", "https://dl.dropboxusercontent.com/s/guf2n9mpky60lz7/IMG-20230326-WA0019.jpg?dl=0", "https://dl.dropboxusercontent.com/s/2lbga0zt9jcvvon/IMG-20230326-WA0018.jpg?dl=0", "https://dl.dropboxusercontent.com/s/yvs7pzo6hkc707d/IMG-20230326-WA0017.jpg?dl=0", "https://dl.dropboxusercontent.com/s/hj93cdsrv4uqmmh/IMG-20230326-WA0016.jpg?dl=0", "https://dl.dropboxusercontent.com/s/mr06priefm169qw/IMG-20230326-WA0015.jpg?dl=0", "https://dl.dropboxusercontent.com/s/hm5wbr8yqjcxk6f/IMG-20230326-WA0014.jpg?dl=0", 
"https://dl.dropboxusercontent.com/s/thyec8s8wssctzz/IMG-20230326-WA0013.jpg?dl=0", "https://dl.dropboxusercontent.com/s/vuejtpgtay38prb/IMG-20230326-WA0012.jpg?dl=0", "https://dl.dropboxusercontent.com/s/wspfidrdo4rzzeq/IMG-20230326-WA0011.jpg?dl=0", "https://dl.dropboxusercontent.com/s/bndiffy4zz4ut9u/IMG-20230326-WA0010.jpg?dl=0", "https://dl.dropboxusercontent.com/s/mv1ipttdlphjb3v/IMG-20230326-WA0009.jpg?dl=0", "https://dl.dropboxusercontent.com/s/mz100xu1o78aab4/IMG-20230326-WA0008.jpg?dl=0", "https://dl.dropboxusercontent.com/s/isjmll082vdqx6t/IMG-20230326-WA0006.jpg?dl=0", "https://dl.dropboxusercontent.com/s/sa3lakht9iy4juk/IMG-20230326-WA0005.jpg?dl=0", "https://dl.dropboxusercontent.com/s/s4s5nw5dbxl8f53/IMG-20230326-WA0004.jpg?dl=0", "https://dl.dropboxusercontent.com/s/13dx2oxh1js9its/IMG-20230326-WA0003.jpg?dl=0", "https://dl.dropboxusercontent.com/s/f1iwsoeoquhgntf/IMG-20230326-WA0002.jpg?dl=0",
"https://dl.dropboxusercontent.com/s/shhkxb0wr93h5cd/IMG-20230326-WA0000.jpg?dl=0"];

export const PARTNERS = [
  ['/dap-logo-green-white.png', 'https://digitalpovertyalliance.org'],
  ['/funded-by-UK-government-logo.png', 'https://www.gov.uk/'],
  ['/RBKC_Logo.svg', 'https://www.rbkc.gov.uk/'], 
  ['/cropped-JLC-logo1.png', 'https://www.jlc.london/'], 
  ['/morley.png', 'https://www.morleycollege.ac.uk/'], 
  ['/london_project.png', 'https://thelondonproject.co.uk/'],
  ['/mf-uk-blue-1536x460.png', 'https://www.mailforcecharity.co.uk/'], 
  ['/motability.png', 'https://www.motability.co.uk/'], 
  ['/supplementaryeducation.png', 'https://www.supplementaryeducation.org.uk/'], 
  ['/gov_ua.png', 'https://uk.mfa.gov.ua/en'], 
  ['/uniquely.png', 'https://www.stpaulsschool.org.uk/'],
  ['/waes-logo.png', 'https://www.waes.ac.uk/'], 
  ['/british-museum-logo.png', 'https://www.britishmuseum.org/'], 
  ['/Bookmark+header+logo.png', 'https://www.bookmarkreading.org/'], 
  ['westway.png', 'https://www.westway.org/'],
  ['/outward.png', 'https://www.outwardbound.org.uk/'],
  ['/HTB+LOGO+3.png', 'https://htb.org/'], 
  ['/nestle.png', 'https://www.nestle.com/'], 
  ['/felix.png', 'https://thefelixproject.org/'], 
  ['portman.png', 'https://portmanestate.co.uk/'], 
  ['/city_of_westnister.png', 'https://www.westminster.gov.uk/'], 
  ['/natural_history.png', 'https://www.nhm.ac.uk/'],
  ['/wilberforcelogo.png', 'https://wilberforceprimary.org/'], 
  ['/cropped-plast-ukraine-logo-1.png', 'https://plastlondon.org.uk/'], 
  ['/CFS-logo.png', 'https://www.cfsurrey.org.uk/fund/surrey-community-fund/'],
  ['/ucharity-logo-large-vectorpng-v2.png', 'https://www.ukrainecharity.org/'],
  ['/kew.png', 'https://www.kew.org/'], 
  ['/tadpolesnursery.png', 'https://www.tadpolesnursery.com/'], 
  ['/cardinal-logo.png', 'https://www.cvms.co.uk/index.asp'], 
  ['/hrp-nav-logo.png', 'https://www.hrp.org.uk/hampton-court-palace/'],
  ['/ua_welcome_center.png', 'https://www.ukrainianwelcomecentre.org/'], 
  ['/trustforlondon.png', 'https://www.trustforlondon.org.uk/'],
  ['/royal_court.png', 'https://royalcourttheatre.com/about/'], 
  ['/YKC_MasterLogo_Blue_2021-01-20-105323.png', 'https://youngkandc.org.uk/'], 
  ['/qpr-crest-blue.png', 'https://www.qpr.co.uk/'], 
];