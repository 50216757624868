import magazine from './images/magazine.png';
import skynews_ukraine from './images/skynews_ukraine.jpeg';
import ft from './images/ft.jpeg';
import Kate_Middleton from './images/Kate_Middleton.jpeg';
import Mayor_awards from './images/Mayor_awards.webp';
import olenaZelenska from './images/ourNews/olena_zelenska.jpeg';
import childrenOfUkraine from './images/ourNews/ChildrenofUkraine.jpeg';
import richmond from './images/ourNews/richmond.png';
import warOneYear from './images/ourNews/war_one_year.jpeg';
import ukDailyNews from './images/ourNews/ukDailyNews.webp';
import teddingtontown from './images/ourNews/teddingtonTown.jpeg';
import Advertiser from './images/ourNews/Advertiser.png';
import pressreader from './images/ourNews/pressreader.png';
import dpaLogo from "./images/dpa-logo-web.webp";

export const LINKS_AND_NEWS = {
    London_Ukraine_march: {
        name: "London Ukraine march attracts hundreds calling for peace",
        source: "https://ichef.bbci.co.uk/news/976/cpsprodpb/6787/production/_132730562_capture.png.webp",
        linkToPage: "https://www.bbc.co.uk/news/uk-england-london-68392144"
    },
    Ukraine_clergy: {
        name: "Don’t forget to help desperate Ukraine clergy, says bishop",
        source: "https://catholic-herald.b-cdn.net/wp-content/uploads/2024/02/Bishop-5-1024x737.jpg",
        linkToPage: "https://catholicherald.co.uk/dont-forget-to-help-desperate-ukraine-clergy-says-bishop/"
    },
    Two_year_anniversary: {
        name: "Two-year anniversary of Ukraine invasion commemorated",
        source: "https://s.yimg.com/ny/api/res/1.2/dRqYI8qp.efrb0EbkW5doA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTY0MTtjZj13ZWJw/https://media.zenfs.com/en/pa_viral_news_uk_120/6db15cd464b76aa7ffe330404dc75f4a",
        linkToPage: "https://uk.news.yahoo.com/pictures-two-anniversary-ukraine-invasion-144155756.html?guccounter=1"
    },
    Headteacher_independent: {
        name: "Headteacher who helped young Ukrainian refugees to be honoured at Windsor Castle",
        source: "https://static.independent.co.uk/2023/10/24/00/15b4b478f9ca4a65e8a271883e53fed8Y29udGVudHNlYXJjaGFwaSwxNjk4MTcyODgw-2.66517059.jpg?quality=75&width=990&crop=3%3A2%2Csmart&auto=webp",
        linkToPage: "https://www.independent.co.uk/news/uk/windsor-castle-mbe-holland-park-ben-tony-blair-b2434767.html"
    },
    Headteacher: {
        name: "Headteacher says MBE is ‘recognition for the whole of the Ukrainian community’",
        source: "https://www.surreycomet.co.uk/resources/images/17366714.jpg?type=mds-article-962",
        linkToPage: "https://www.surreycomet.co.uk/news/national/23876624.headteacher-says-mbe-recognition-whole-ukrainian-community"
    },
    Zakhar: {
        name: "Every time 10-year-old Zakhar heard the school fire alarm, he hid under his desk...",
        source: "https://i.dailymail.co.uk/1s/2023/09/13/22/75403335-12515529-image-a-28_1694639785527.jpg",
        linkToPage: "https://www.dailymail.co.uk/news/article-12515529/Every-time-10-year-old-Zakhar-heard-school-fire-alarm-hid-desk-like-hundreds-war-scarred-Ukrainian-child-refugees-UK-hes-taught-cope-terrifying-memories.html"
    },
    Kingston: {
        name: "New Ukrainian school opens its doors in Kingston",
        source: "https://www.kingston.gov.uk/images/DV_009.jpg",
        linkToPage: "https://www.kingston.gov.uk/news/article/429/new-ukrainian-school-opens-its-doors-in-kingston"
    },
    POLYTECHNIC: {
        name: "The Department of Ukrainian Language continues to successfully cooperate with the Association of Ukrainian Teachers in Great Britain",
        source: "https://lpnu.ua/sites/default/files/2023/9/6/news/24507/um-20230906072354-t.jpg",
        linkToPage: "https://lpnu.ua/en/news/department-ukrainian-language-continues-successfully-cooperate-association-ukrainian-teachers"
    },
    schoolHillingdon: {
        name: "Welcoming St Mary's Ukrainian School to Hillingdon",
        source: "https://www.davidsimmonds.org.uk/sites/www.davidsimmonds.org.uk/files/styles/gallery_large/public/news-gallery/unnamed.jpg?itok=tf55OlGV",
        linkToPage: "https://www.davidsimmonds.org.uk/news/welcoming-st-marys-ukrainian-school-hillingdon"
    },
    schoolLeader: {
        name: "School leaders and governors named in King’s birthday honours",
        source: "https://schoolsweek.co.uk/wp-content/uploads/2020/01/OBE-medal-honours-feat-1920x1008.jpg",
        linkToPage: "https://schoolsweek.co.uk/school-leaders-and-governors-named-in-kings-birthday-honours"
    },
    oneYearOfwar: {
        name: "Ukrainian children lead poignant London service marking one year of war",
        source: "https://thenational-the-national-prod.cdn.arcpublishing.com/resizer/VgERvQOgvLFlY6YmZiGOh6nuJUQ=/800x0/filters:format(jpg):quality(70)/cloudfront-eu-central-1.images.arcpublishing.com/thenational/7RES4HQGOZM3V56UM55VQCLCW4.jpg",
        linkToPage: "https://www.thenationalnews.com/world/uk-news/2023/02/24/ukrainian-children-lead-poignant-london-service-marking-one-year-of-war/"
    },

    shappingOurFuture: {
       name: "Shaping Our Future Celebration Event",
       source: "https://stpauls.fra1.digitaloceanspaces.com/wp-content/uploads/2023/05/28124937/042.jpg",
       linkToPage: "https://www.stpaulsschool.org.uk/shaping-our-future-celebration-event/"
    },

    caseStudy: {
      name: "Case Study: St Marys Ukrainian School, London",
      source: dpaLogo,
      linkToPage: "https://digitalpovertyalliance.org/case-studies/case-study-st-marys-ukrainian-school-london/"
    },

    FelicityBuchan: {
        name: "Felicity Buchan Visits Ukrainian School",
        source: "https://www.felicitybuchan.com/sites/www.felicitybuchan.com/files/styles/gallery_large/public/news-gallery/ukraine%20school%201.jpg?itok=TaaJxPRZ",
        linkToPage: "https://www.felicitybuchan.com/news/felicity-buchan-visits-ukrainian-school"
    },

    olenaZelenska: {
        name: "Olena Zelenska arrives at the coronation ceremony of His Majesty Charles III",
        source: olenaZelenska,
        linkToPage: "https://www.president.gov.ua/en/news/olena-zelenska-pribula-na-ceremoniyu-koronaciyi-jogo-velichn-82689"
    },

    childrenOfUkraine: {
        name: "Children of Ukraine Event",
        source: childrenOfUkraine,
        linkToPage: "https://www.stpaulsschool.org.uk/children-of-ukraine-event/"
    },

    richmondSchool: {
        name: "Ukrainian school officially opens in Richmond",
        source: richmond,
        linkToPage: "https://richmond.nub.news/news/local-news/ukrainian-school-officially-opens-in-richmond-182726"
    },

    richmondSchool_v2: {
        name: "NEWS: Ukrainian school opens in Richmond upon Thames",
        source: teddingtontown,
        linkToPage: "https://teddingtontown.co.uk/2023/05/03/news-ukrainian-school-opens-in-richmond-upon-thames/"
    },

    ukraineWarOneYear: {
        name: "Ukraine war one year on – remembrance in pictures",
        source: warOneYear,
        linkToPage: "https://www.swlondoner.co.uk/news/02032023-ukraine-war-one-year-on-remembrance-in-pictures"
    },

    richmondSchool_v3: {
        name: "Opening of a Ukrainian school in Richmond upon Thames",
        source: ukDailyNews,
        linkToPage: "https://ukdaily.news/london/opening-of-a-ukrainian-school-in-richmond-upon-thames-207951.html"
    },

    richmondSchool_v4: {
        name: "Ukrainian school opens in Richmond upon Thames",
        source: richmond,
        linkToPage: "https://www.richmond.gov.uk/news/may_2023/ukrainian_school_opens_in_richmond"
    },

    telegraphChoir: {
        name: "Ukrainian children's choir sing at Princess of Wales’s concert to boost morale on front lines",
        source: "https://www.telegraph.co.uk/content/dam/royal-family/2022/12/22/TELEMMGLPICT000320373375_trans_NvBQzQNjv4BqscKuyqQp6IXRzod7hjCu_tHhMh81Y8GqX3ipA8YMo0k.jpeg?imwidth=1280",
        linkToPage: "https://www.telegraph.co.uk/royal-family/2022/12/22/ukrainian-childrens-choir-sing-princess-waless-concert-boost/"
    },

    dailymailKate: {
        name: "I feel like Kate's my friend': Children from St Mary's Ukrainian School choir recall 'high-fiving the Princess of Wales' before performing at her festive carol service",
        source: "https://i.dailymail.co.uk/1s/2022/12/23/10/65880745-0-image-a-2_1671792193572.jpg",
        linkToPage: "https://www.dailymail.co.uk/femail/article-11569183/amp/Children-St-Marys-Ukrainian-School-choir-recall-high-fiving-Kate-Middleton.html"
    }, 
    
    rbkcGovUk: {
        name: "Ceremony celebrates Mayor's Award winners",
        source: Mayor_awards,
        linkToPage: "https://www.rbkc.gov.uk/newsroom/ceremony-celebrates-mayors-award-winners"
    },

    cnwlNhsUk: {
        name: "An Evening of Thanks",
        source: "https://www.cnwl.nhs.uk/application/files/4116/7939/5581/Prolisok_Dance_Group.jpg",
        linkToPage: "https://www.cnwl.nhs.uk/news/evening-thanks"
    },

    schoolMagazine: {
        name: "international schools magazine",
        source: magazine,
        linkToPage: "https://issuu.com/williamclarence/docs/international_schools_magazine_autumn_22"
    },

    skynewsUkraine: {
        name: "Ukraine war",
        source: skynews_ukraine,
        linkToPage: "https://news.sky.com/story/ukraine-war-102-year-old-fundraiser-holds-silence-in-support-of-ukrainian-refugees-from-his-garden-12591671"
    },

    standard: {
        name: "London Ukraine school",
        source: "https://static.standard.co.uk/2022/05/12/11/newFile-11.jpg?width=1024&auto=webp&quality=50&crop=968%3A645%2Csmart",
        linkToPage: "https://www.standard.co.uk/news/london/st-marys-ukrainian-school-london-urgent-help-refugee-pupils-b999590.html"
    },

    ft: {
        name: "What happens next",
        source: ft,
        linkToPage: "https://www.ft.com/content/405b22b2-3b1f-499d-a794-e3a9d4326740"
    },

    bbc: {
        name: "Ukrainian children in London",
        source: "https://ichef.bbci.co.uk/news/976/cpsprodpb/12F37/production/_123432677_whatsappimage2022-02-26at10.48.15.jpg.webp",
        linkToPage: "https://www.bbc.co.uk/news/uk-england-london-60536596"
    },

    celebrity: {
        name: "Kate Middleton",
        source: Kate_Middleton,
        linkToPage: "https://celebrity.land/kate-middleton-y-el-principe-william-hablan-de-su-frustracion-por-ucrania-nos-sentimos-inutiles-real-noticias/"
    },

    kerridgecs: {
        name: "KCS partners",
        source: "https://www.kerridgecs.com/file/750x200-header-Computer-Donation-kcs.png",
        linkToPage: "https://www.kerridgecs.com/articles/news/1603"
    },

    headtopics: {
        name: "Ukrainian children in London",
        source: "https://ichef.bbci.co.uk/news/976/cpsprodpb/12F37/production/_123432677_whatsappimage2022-02-26at10.48.15.jpg.webp",
        linkToPage: "https://headtopics.com/uk/ukrainian-children-in-london-we-are-worried-about-our-families-24336633"
    },

    dailymail: {
        name: "Millions more smiles",
        source: "https://i.dailymail.co.uk/1s/2022/07/06/01/59911159-10985821-image-m-139_1657066046230.jpg",
        linkToPage: "https://www.dailymail.co.uk/news/article-10985821/Mail-Force-offers-extra-places-summer-camps-Ukrainian-children-following-overwhelming-demand.html"
    },

    standardTeachers: {
        name: "Teachers mustn’t be afraid",
        source: "https://static.standard.co.uk/2022/03/04/13/image3.jpeg?width=1024&auto=webp&quality=50&crop=968%3A645%2Csmart",
        linkToPage: "https://www.standard.co.uk/news/uk/ukraine-war-teachers-schools-at-marys-ukrainian-school-london-b986112.html"
    },

    independent: {
        name: "William and Kate reveal",
        source: "https://static.independent.co.uk/2022/03/09/17/cdf721d4be61a89eec8f913f907f4fbdY29udGVudHNlYXJjaGFwaSwxNjQ2OTMxMTc3-2.65754161.jpg?quality=75&width=990&auto=webp&crop=982:726,smart",
        linkToPage: "https://www.independent.co.uk/news/uk/ukraine-charlotte-kensington-palace-europe-prince-george-b2032253.html"
    },

    bussinessinsider: {
        name: "Fearful Ukrainian expats",
        source: "https://www.businessinsider.in/thumb/msid-89530277,width-700,resizemode-4,imgsize-71768/international/news/fearful-ukrainian-expats-recall-the-dark-days-of-the-soviet-era-as-russias-military-buildup-reaches-its-most-dangerous-moment-yet/img61fe98f2bd5041001918e638.jpg",
        linkToPage: "https://www.businessinsider.in/international/news/fearful-ukrainian-expats-recall-the-dark-days-of-the-soviet-era-as-russias-military-buildup-reaches-its-most-dangerous-moment-yet/articleshow/89530544.cms"
    },

    eventsforlondon: {
        name: "Ukrainian Charity Concert",
        source: "https://eventsforlondon.co.uk/wp-content/uploads/2022/07/5359-Ukrainian-Charity-Night-poster-A3-e1658819214468.jpg",
        linkToPage: "https://eventsforlondon.co.uk/event/ukrainian-charity-concert/"
    },

    telegraph: {
        name: "The Cambridges show",
        source: "https://www.telegraph.co.uk/content/dam/royal-family/2022/03/09/TELEMMGLPICT000000288824654_trans_NvBQzQNjv4BqpVlberWd9EgFPZtcLiMQf_4Xpit_DMGvdp2n7FDd82k.jpeg?imwidth=1280",
        linkToPage: "https://www.telegraph.co.uk/royal-family/2022/03/09/cambridges-show-support-ukraine-bag-cakes-meet-volunteers/"
    },

    tivysid: {
        name: "William and Kate reveal",
        source: Advertiser,
        linkToPage: "https://www.tivysideadvertiser.co.uk/news/national/19981318.william-kate-reveal-charlotte-george-quiz-ukraine-war/"
    },

    pressread: {
        name: "I choose my words",
        source: pressreader,
        linkToPage: "https://www.pressreader.com/uk/daily-express/20220310/281646783608043"
    },

    royalnews: {
        name: "Prince William and Duchess",
        source: "https://i.prcdn.co/img?regionKey=8na%2bWyIPblMVHYZcgVtvZQ%3d%3d",
        linkToPage: "https://royalnewsnetwork.com/2022/03/09/%EF%BF%BCprince-william-and-duchess-catherine-make-an-emotional-visit-to-ukrainian-cultural-center-bringing-homemade-brownies-and-granola-bars-from-kensington-palace/"
    },

    thetimes: {
        name: "Trauma and hope: inside a London centre for Ukrainian families",
        source: "https://www.thetimes.co.uk/imageserver/image/%2Fmethode%2Ftimes%2Fprod%2Fweb%2Fbin%2F793478d8-b417-11ed-abc9-a9456bea4494.jpg?crop=7950%2C4472%2C73%2C140&resize=1500",
        linkToPage: "https://www.thetimes.co.uk/article/inside-ukraine-centre-west-london-families-holland-park-uk-tk9kps98l?fbclid"
    },

    standardLargest: {
        name: "London’s largest Ukrainian school is a lifeline for displaced children craving normality",
        source: "https://static.standard.co.uk/2022/04/05/13/Ukraine-schoole.jpg?width=1024&auto=webp&quality=50&crop=968%3A645%2Csmart",
        linkToPage: "https://www.standard.co.uk/insider/ukraine-school-london-holland-park-refugees-b992590.html"
    },
};

export const MEDIA = {
    youtube1: 'https://www.youtube.com/embed/cEIGEcCqy0k',
    youtube2: 'https://www.youtube.com/embed/qV36Ypt0EJ4',
    youtube3: 'https://www.youtube.com/embed/54ESNwhP9f4',
    istagram1: 'https://www.instagram.com/reel/Ci-eTaUMwNj/embed',
    vimeo1: 'https://player.vimeo.com/video/719218461',
    podbean: "https://www.podbean.com/player-v2/?from=embed&i=7uaik-121b62d-pb&square=1&share=1&download=1&fonts=Arial&skin=1b1b1b&font-color=auto&rtl=0&logo_link=&btn-skin=3ab278&size=300"
};