import SecondaryPagesImage from '../../components/SecondaryPagesImage';
import SecondaryPagesBanner from '../../components/SecondaryPagesBanner';
import ourTeam from '../../images/our-team.jpeg';
import { Trans, withTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import history from '../../images/history.jpeg';
import news from '../../images/news.jpeg';
import vacancies from '../../images/vacancies.jpeg';

function MessageFromHT() {
    let { pathname } = useLocation();

    pathname = pathname.replace('/about-us/', '')

    const MAIN_BLOCK_DATA = {
       'message-from': {
          header: `message from`,
          image: ourTeam,
          text: `welcome to`
       },
       'our-history': {
        header: `our history`,
        image: history,
        text: `established in london`
       },
       'our-team': {
        header: 'passionate team',
        image: ourTeam,
        text: 'people who make our school'
       },
       'st-marys-in-media-and-news': {
        header: 'Media and News',
        image: news,
        text: ''
       },
       'vacancies': {
        header: 'Vacancies',
        image: vacancies,
        text: 'introduces current vacancies'
       }
    };

    return(
      <>
        <SecondaryPagesImage image={MAIN_BLOCK_DATA[pathname].image} />
        <SecondaryPagesBanner header={<Trans i18nKey={MAIN_BLOCK_DATA[pathname].header}/>} 
        text={<Trans i18nKey={MAIN_BLOCK_DATA[pathname].text}/>}/>
        <Outlet />
      </>
    );
}

export default withTranslation()(MessageFromHT);