import SecondaryPagesImage from '../../components/SecondaryPagesImage';
import SecondaryPagesBanner from '../../components/SecondaryPagesBanner';
import profile from '../../images/profile.jpeg';
import { t } from 'i18next';
import { Trans, withTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import curriculum from '../../images/curriculum.jpeg';
import schoolDay from '../../images/school-day.jpeg';
import slide1 from '../../images/slide1.jpeg';
import parents from '../../images/parents.jpeg';

function MessageFromHT() {
    let { pathname } = useLocation();

    pathname = pathname.replace('/school-info/', '')

    const MAIN_BLOCK_DATA = {
       'school-profile': {
          header: `School profile`,
          image: profile,
          text: `largest Ukrainian Saturday community`
       },
       'school-curriculum': {
        header: `School curriculum`,
        image: curriculum,
        text: 'we believe that all children'
       },
       'school-day-and-terms': {
        header: 'School day & terms',
        image: schoolDay,
        text: 'school terms and a typical day'
       },
       'school-policies': {
        header: 'School policies',
        image: slide1,
        text: 'school terms and a typical day'
       },
       'parent-teacher-association': {
        header: 'parents committee',
        image: parents,
        text: 'you can find'
       }
    };

    return(
      <>
        <SecondaryPagesImage image={MAIN_BLOCK_DATA[pathname].image} />
        <SecondaryPagesBanner header={<Trans i18nKey={MAIN_BLOCK_DATA[pathname].header} />} 
        text={<Trans i18nKey={MAIN_BLOCK_DATA[pathname].text}/>}/>
        <Outlet />
      </>
    );
}

export default withTranslation()(MessageFromHT);