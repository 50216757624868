import { createSlice } from '@reduxjs/toolkit';
import { LINKS_AND_NEWS } from './data/newsAndMedia';
import { MEDIA } from './data/newsAndMedia';

const initialState = {
    news: LINKS_AND_NEWS,
    media: MEDIA
}

export const newsAndMediaSlice = createSlice({
    name: 'newsAndMedia',
    initialState,
    reducers: {
        setNews: (state, action) => {
            state.news = action.payload;
        },
        setMedia: (state, action) => {
            state.media = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setNews, setMedia } = newsAndMediaSlice.actions

export default newsAndMediaSlice.reducer