const listOfEvents = [{
    title: 'Autumn Term',
    start: new Date(2023, 8, 2),
    end: new Date(2023, 11, 16),
}, {
    title: 'Inset day',
    start: new Date(2023, 8, 2),
    end: new Date(2023, 8, 2),
}, {
    title: 'Pershyy Dsvonyk',
    start: new Date(2023, 8, 9),
    end: new Date(2023, 8, 9),
}, {
    title: 'Покрови',
    start: new Date(2023, 8, 31),
    end: new Date(2023, 8, 31),
}, {
    title: 'Козацькі забави',
    start: new Date(2023, 9, 7),
    end: new Date(2023, 9, 7),
}, {
    title: 'День Памяті жертв Голодомору',
    start: new Date(2023, 10, 25),
    end: new Date(2023, 10, 25),
}, {
    title: 'Злука',
    start: new Date(2023, 9, 28),
    end: new Date(2023, 9, 28),
}, {
    title: 'Злука',
    start: new Date(2023, 10, 4),
    end: new Date(2023, 10, 4),
}, {
    title: 'Свято Миколая',
    start: new Date(2023, 11, 2),
    end: new Date(2023, 11, 2),
}, {
    title: 'Свято Миколая',
    start: new Date(2023, 11, 9),
    end: new Date(2023, 11, 9),
}, {
    title: 'Останній день осіннього семестру',
    start: new Date(2023, 11, 16),
    end: new Date(2023, 11, 16),
}, {
    title: 'Spring term',
    start: new Date(2024, 0, 13),
    end: new Date(2024, 6, 6),
}, {
    title: 'Спільна коляда/ Візит Владики та Посвячення школи',
    start: new Date(2024, 0, 13),
    end: new Date(2024, 0, 13),
}, {
    title: 'День Соборності',
    start: new Date(2024, 0, 20),
    end: new Date(2024, 0, 20),
}, {
    title: 'День памяті Небесної Сотні',
    start: new Date(2024, 1, 17),
    end: new Date(2024, 1, 17),
}, {
    title: 'Друга Річниця війни',
    start: new Date(2024, 1, 24),
    end: new Date(2024, 1, 24),
}, {
    title: 'Шевченківські дні',
    start: new Date(2024, 2, 2),
    end: new Date(2024, 2, 2),
}, {
    title: 'Шевченківські дні',
    start: new Date(2024, 2, 9),
    end: new Date(2024, 2, 9),
}, {
    title: 'День Вареника',
    start: new Date(2024, 2, 16),
    end: new Date(2024, 2, 16),
}, {
    title: 'Великодні канікули',
    start: new Date(2024, 3, 31),
    end: new Date(2024, 3, 31),
}, {
    title: 'Великодні канікули',
    start: new Date(2024, 4, 5),
    end: new Date(2024, 4, 5),
}, {
    title: 'День матері',
    start: new Date(2024, 4, 11),
    end: new Date(2024, 4, 11),
}, {
    title: 'День Вишиванки та Марш Вишиванок',
    start: new Date(2024, 4, 18),
    end: new Date(2024, 4, 18),
}, {
    title: 'Іспит УКУ',
    start: new Date(2024, 5, 22),
    end: new Date(2024, 5, 22),
}, {
    title: 'Останній Дзвоник',
    start: new Date(2024, 4, 29),
    end: new Date(2024, 4, 29),
}, {
    title: 'Педрада',
    start: new Date(2024, 6, 6),
    end: new Date(2024, 6, 6),
}, {
    title: 'Останній день року',
    start: new Date(2024, 6, 6),
    end: new Date(2024, 6, 6),
}];

export let eventsList = () => {
    return listOfEvents.map((e, index) => {
         return {
            id: index,
            allDay: true,
            title: e.title,
            start: e.start,
            end: e.end
         }
    });
};