import React, { useState, useRef } from 'react';
import instagram from '../images/instagram.svg';
import facebook from '../images/fb.svg';
import twitter from '../images/twitter.svg';
import youtube from '../images/youtube.svg';
import callback from '../images/callback.svg';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { Trans } from 'react-i18next';

function SocialMedia() {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <>
            <Row style={{ position: 'fixed', top: '20%', marginLeft: '2px', zIndex: '10' }} className="d-none d-sm-none d-md-block">
                <Col className='mb-2 mt-2'>
                    <a href="https://instagram.com/stmarysukrschool?igshid=YmMyMTA2M2Y=" target="_blank" style={{ marginRight: '10px' }} rel="noreferrer">
                        <img src={instagram} width="35" height="35" alt="Instagram Logo" />
                    </a>
                    <Col className='mb-2 mt-2'>
                        <a href="https://www.facebook.com/groups/1397307640576937/?mibextid=6NoCDW" target="_blank" rel="noreferrer">
                            <img src={facebook} width="35" height="35" alt="Facebook Logo" />
                        </a>
                    </Col>
                    <Col className='mb-2 mt-2'>
                        <a href="https://twitter.com/stmarysukrsch" target="_blank" rel="noreferrer">
                            <img src={twitter} width="35" height="35" alt="Twitter Logo" />
                        </a>
                    </Col>
                    <Col className='mb-2 mt-2'>
                        <a href="https://www.youtube.com/@StMarysUkrSchool" target="_blank" rel="noreferrer">
                            <img src={youtube} width="35" height="35" alt="YouTube Logo" />
                        </a>
                    </Col>
                    <Col className='mb-2 mt-2'>
                        <Button ref={target} onClick={() => setShow(!show)} style={{ background: 'none', border: 'none', padding: '0' }}>
                          <img src={callback} width="35" height="35" alt="Callback Logo" />
                        </Button>
                        <Overlay target={target.current} show={show} placement="right">
                            {(props) => (
                                <Tooltip id="overlay" {...props}>
                                    <Trans i18nKey="Call Us" />
                                </Tooltip>
                            )}
                        </Overlay>
                    </Col>
                </Col>
            </Row>
        </>
    )
}

export default SocialMedia;