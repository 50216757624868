import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import Image from 'react-bootstrap/Image'
import { NavLink } from "react-router-dom";
import { Trans } from 'react-i18next';
import ukrainian from '../images/ukrainian.svg';
import bars from '../images/bars.svg'
import ukraine from '../images/ukraine.svg';
import uk from '../images/uk.svg';
import LanguageButton from '../components/LanguageButton';
import { NAVIGATION_ABOUT_US, NAVIGATION_SCHOOL_INFO, NAVIGATION_SATELLITE_SCHOOLS, NAVIGATION_HOME } from '../data/NavigationData';

function SideBar(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function navigationGeneration(navigationItems) {
        let navigationArray = [];

        for (let key in navigationItems) {
            navigationArray.push(
                <Row key={key} style={{ borderBottom: '1px dotted black', textAlign: 'center' }}>
                    <NavLink onClick={() => setShow()} className="sideNavigationLink" to={navigationItems[key].linkTo}>
                        <Trans ns="navigation" i18nKey={navigationItems[key].name} />
                    </NavLink>
                </Row>
            )
        }

        return navigationArray;
    }

    return (
        <>
            <Row style={{ backgroundColor: '#185e81' }}>
                <Col className="d-block d-sm-block d-md-none">
                    <Button size="sm" className={`supportUsButton`} href="https://donate.stmarysukrschool.co.uk/" target="_blank">
                        <Trans i18nKey="Support Us" />
                    </Button>
                </Col>
                <Col className="justify-content-end d-flex">
                    <Button variant="light" onClick={handleShow} className={props.className} style={{ width: '45px', alignItems: "right", backgroundColor: 'none', background: 'none', 'border': 'none' }}>
                        <Image src={bars} />
                    </Button>
                </Col>
            </Row>
            <Offcanvas show={show} onHide={handleClose} className={`${props.className}`}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <NavLink to='/'> <img
                            src={ukrainian}
                            width="100"
                            height="100"
                            alt="Ukrainian Logo"
                        /></NavLink>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Accordion flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><Trans i18nKey="Home" /></Accordion.Header>
                            <Accordion.Body>
                                {navigationGeneration(NAVIGATION_HOME)}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><Trans i18nKey="about us" /></Accordion.Header>
                            <Accordion.Body>
                                {navigationGeneration(NAVIGATION_ABOUT_US)}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><Trans i18nKey="school info" /></Accordion.Header>
                            <Accordion.Body>
                                {navigationGeneration(NAVIGATION_SCHOOL_INFO)}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><Trans i18nKey="satellite schools" /></Accordion.Header>
                            <Accordion.Body>
                                {navigationGeneration(NAVIGATION_SATELLITE_SCHOOLS)}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><Trans i18nKey="Contact Us" /></Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ borderBottom: '1px dotted black' }}>
                                    <NavLink onClick={() => setShow()} className="sideNavigationLink" to='/contact-us'>
                                        <Trans i18nKey="Contact Us" />
                                    </NavLink>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Col style={{ marginTop: '10%' }}>
                        <LanguageButton localisation="en" logo={uk} alt="UK flag" />
                        <LanguageButton localisation="ua" logo={ukraine} alt="Ukrainian flag" />
                    </Col>
                    <Col style={{ marginTop: '15%', bottom: '0' }}>
                        <a href='tel:+442037954966'><p><Trans i18nKey="Call Us" /></p></a>
                    </Col>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default SideBar;