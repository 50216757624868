import MainBlockLayout from '../../components/MainBlockLayout';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enGB from 'date-fns/locale/en-GB'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useSelector } from 'react-redux';

const locales = {
    'en-GB': enGB,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

function MainBlock() {
    let events = useSelector(state => state.calendar.calendar);
    
    return (
        <>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
            />
        </>
    )
}

export default MainBlockLayout(MainBlock);