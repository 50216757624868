import { configureStore } from '@reduxjs/toolkit'
import { contactUsSlice } from './slices/ContactUs/slice'
import { satelliteSchoolsSlice } from './slices/SatelliteSchools/slice'
import { newsAndMediaSlice } from './slices/NewsAndMedia/slice'
import { calendarSlice } from './slices/Calendar/slice'
import { newsSlice } from './slices/News/slice'

export const store = configureStore({
  reducer: {
    form: contactUsSlice.reducer,
    satelliteSchools: satelliteSchoolsSlice.reducer,
    newsAndMedia: newsAndMediaSlice.reducer,
    calendar: calendarSlice.reducer,
    news: newsSlice.reducer,
  },
})