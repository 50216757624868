import Col from 'react-bootstrap/Col';
import ReactPlayer from 'react-player';
import { useState } from 'react';
import { Row } from 'react-bootstrap';

function Slider() {
    const [control, setControl] = useState(false);

    return (
        <>
            <Row className="slider" onClick={() => { setControl(true) }}>
                <Col md={12}>
                <ReactPlayer playsinline={true}
                    url={`${process.env.PUBLIC_URL}/video/promo2.mov`} controls={control} playing={true} muted={true}
                    width="100%" height="inherited" loop={true} />
                </Col>
             
            </Row>
        </>
    )
}

export default Slider;