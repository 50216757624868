import { t } from 'i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MainBlockLayout from '../../components/MainBlockLayout';

function MainBlock() {

    return (
        <>
            <Row md='12'>
                <Col md='12'>
                  <p>***</p>
                  <p>{t('new vacancy')} <a href='https://cleverstaff.net/i/vacancy-JWPjFI'>cleverstaff.net</a></p>
                  <p>{t('have necessary qualifications')} <a href="https://cleverstaff.net/i/vacancy-uDddw4">cleverstaff.net</a></p>
                  <p>{t('Kind Regards')}</p>
                  <p>{t('School Leadership Team')}</p>
                </Col>
            </ Row>
        </>
    )
}

export default MainBlockLayout(MainBlock);