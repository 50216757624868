import kingston_september from './files/September/Kingston.pdf';
import cardinal_september from './files/September/Cardinal.pdf';
import woking_september from './files/September/Woking.pdf';
import richmond_september from './files/September/Richmond.pdf';
import tunbridge_september from './files/September/Tunbridge.pdf';
import wilberforce_september from './files/September/Wilberforce.pdf';
import holland_september from './files/September/Holland Park.pdf';

import kingston_november from './files/November/Kingston November.pdf';
import cardinal_november from './files/November/Cardinal November.pdf';
import woking_november from './files/November/Woking November .pdf';
import richmond_november from './files/November/Richmond November .pdf';
import tunbridge_november from './files/November/Tunbridge November.pdf';
import wilberforce_november from './files/November/Willbeforce November.pdf';
import holland_november from './files/November/Holland Park November .pdf';
import hillingdon_november from './files/November/Hillingdon November.pdf';

const createLocationData = (september, november, name) => [
  {
    pdf: september,
    originalName: september ? `${name} September` : null,
  },
  {
    pdf: november,
    originalName: november ? `${name} November` : null,
  },
];

let kingston = createLocationData(kingston_september, kingston_november, 'Kingston');
let cardinal = createLocationData(cardinal_september, cardinal_november, 'Cardinal');
let woking = createLocationData(woking_september, woking_november, 'Woking');
let richmond = createLocationData(richmond_september, richmond_november, 'Richmond');
let tunbridge = createLocationData(tunbridge_september, tunbridge_november, 'Tunbridge');
let wilberforce = createLocationData(wilberforce_september, wilberforce_november, 'Wilberforce');
let holland = createLocationData(holland_september, holland_november, 'Holland Park');
let hillingdon = createLocationData('', hillingdon_november, 'Hillingdon');

export const NEWSLETTERS = {
  kingston: kingston,
  cardinal: cardinal,
  woking: woking,
  richmond: richmond,
  tunbridge: tunbridge,
  wilberforce: wilberforce,
  holland: holland,
  hillingdon: hillingdon
};