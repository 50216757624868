import Col from 'react-bootstrap/Col';
import augb from '../images/AUGB.png';
import ukgov from '../images/funded-by-UK-government-logo.png'
import { Trans } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';

function Footer() {
  return (
    <>
    <Container fluid>
      <Row className='footerTop'>
        <div style={{ height: '5px' }}></div>
        <Col className="justify-content-start footerText" sm={8} md={10}>
          <Trans i18nKey="copiright"></Trans>
        </Col>
        <Col md={2} className="d-flex justify-content-end footerLogoBlock" style={{ marginTop: '10px' }}>
        <img style={{marginRight:'10px'}}
            className="footerLogo"
            src={ukgov}
            alt="Funded by UK Government Logo"
          />

          <img
            className="d-none d-sm-none d-md-flex footerLogo"
            src={augb}
            alt="AUGB Logo"
          />
        </Col>
      </Row>
      </Container>
    </>
  )
}

export default Footer;