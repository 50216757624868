import Reaptcha from 'reaptcha';
import { useSelector } from 'react-redux';

function CaptchaComponent({ onCaptchaVerify, captchaRef, allowedToSend, verify }) {
    const captcha = useSelector(state => state.form.captcha);
    const captchaValid = captcha.isValid;
    
   return(
    <>
     {
        allowedToSend ? <Reaptcha
            sitekey={process.env.REACT_APP_SITE_KEY}
            size="normal"
            ref={captchaRef}
            onVerify={onCaptchaVerify}
        /> : <></>
    }
    </>
   )
}

export default CaptchaComponent;