import Form from 'react-bootstrap/Form';
import { t } from 'i18next';

function FormField({ name, type, label, placeholder, isInvalid, onChange }) {
    return (
        <Form.Group className="mb-3" controlId={`formBasic${name}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control required name={name} type={type} placeholder={placeholder} isInvalid={isInvalid} onChange={onChange} />
            <Form.Control.Feedback type="invalid">
                {t(`please enter ${name}`)}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default FormField;