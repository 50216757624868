import { t } from 'i18next';
import Card from 'react-bootstrap/Card';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MainBlockLayout from '../../components/MainBlockLayout';
import { useSelector } from 'react-redux';

function MainBlock() {
    const news = useSelector(state => state.newsAndMedia.news);
    const media = useSelector(state => state.newsAndMedia.media);

    function generateNews() {
        let arrayOfNews = [];

        for (let key in news) {
            arrayOfNews.push(
                <Col>
                    <Card style={{ width: '85%', marginBottom: "10%", alignItems: 'center' }}>
                        <Card.Img variant="top" src={news[key].source} style={{ width: '85%' }} />
                        <Card.Body>
                            <Card.Title>{t(news[key].name)}</Card.Title>
                            <Card.Link href={news[key].linkToPage} target="_blank">{t('Read more')}</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
            )
        }

        return arrayOfNews;
    }

    return (
        <>
                <h3 style={{ marginBottom: '30px', marginTop: '20px', marginLeft: '20px' }}>{t('St Marys in News')}</h3>
                <Row xs={1} sm={2} md={4} style={{ marginLeft: '20px' }}>
                    {generateNews()}
                </Row>
                <h3 style={{ marginBottom: '30px', marginTop: '20px' }}>{t('St Marys Videos')}</h3>

                <Row xs={1} sm={2} md={4}>
                    <iframe width="420" height="315" src={media.youtube1} title="St Mary&#39;s Ukrainian Saturday school" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="420" height="315" src={media.youtube2} title="Case Study: St Marys Ukrainian School, London" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="420" height="315" src={media.youtube3} title="Kate Middleton&#39;s Ukrainian Choir Pays Tribute To Queen Elizabeth With Royal Carol Concert | Lorraine" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="220" height="315"
                        title="kalush"
                        src={media.istagram1}>
                    </iframe>
                    <iframe width="420" height="315" className="mt-3"
                        title="school activities"
                        src={media.vimeo1}>
                    </iframe>
                </Row>
                <h3 style={{ marginBottom: '30px', marginTop: '20px' }}>{t('St Marys Podcasts')}</h3>
                <iframe title="The Morning Break with Holly King-Mand 05-05-22: Supporting Ukrainian Refugees in Our Schools" allowtransparency="true" height="160" width="100%" src={media.podbean}></iframe>
        </>
    )
}

export default MainBlockLayout(MainBlock);