import { createSlice } from '@reduxjs/toolkit';
import { SCHOOLS_LIST } from './data/schools';
import { NEWSLETTERS } from './data/newsletters';

const initialState = {
    schools_list: SCHOOLS_LIST,
    schools_newsletters: NEWSLETTERS
}

export const satelliteSchoolsSlice = createSlice({
    name: 'satelliteSchools',
    initialState,
    reducers: {
        setSchoolsList: (state, action) => {
            state.schools_list = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setSchoolsList } = satelliteSchoolsSlice.actions

export default satelliteSchoolsSlice.reducer