import { t } from 'i18next';
import ClockLoader from "react-spinners/ClockLoader";
import ImageGallery from 'react-image-gallery';

export function generateSimpleList(list) {
  return list.map((item) => <li>{t(item)}</li>);
}

export function importAll(images) {
  let Allimages = {};
   images.keys().forEach((item, index) => { Allimages[item.replace('./', '')] = images(item); });
  return Allimages
 }

 function prepareImageForSlider(ObjectOFimages) {
  let images = [];

  for (let image in ObjectOFimages) {
      images.push(
          {
              original: ObjectOFimages[image],
              thumbnail: ObjectOFimages[image],
          }
      )
  }

  return images;
 }

 export function generateImageTabs(images, loading, setLoading, thumbnailPosition="left") {
  return (
      <>
          <div style={{ display: loading ? "none" : "block" }}>
              <ImageGallery items={prepareImageForSlider(images)} autoPlay={true} onImageLoad={() => setLoading(false)}
                  thumbnailPosition={thumbnailPosition} lazyLoad={true} slideInterval={8000} />
          </div>
          <div style={{ display: 'flex', webkitBoxPack: 'center', placeContent: 'center', placeItems: 'center', webkitBoxAlign: 'center' }}>
              <ClockLoader
                  color={"#feda00"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
              />
          </div>

      </>
  )
}