import surrey from './images/Surrey.jpg'
import cardinal from './images/Cardinal.jpeg'
import richmond from './images/richmond.jpeg'
import tunbridge from './images/tunbridge.jpg'
import wilberforce from './images/wilberforce.jpg'
import kingston from './images/Kingston.jpg'
import holland from './images/holland.jpg'
import nursery from './images/nursery.jpeg'
import hillingdon from './images/hillingdon.jpg'
import glasgow from './images/Glasgow.jpeg'

export const SCHOOLS_LIST = [
    {
        name: 'woking',
        image: surrey
    },
    {
        name: 'cardinal',
        image: cardinal
    },
    {
        name: 'richmond',
        image: richmond
    },
    {
        name: 'tunbridge',
        image: tunbridge
    },
    {
        name: 'wilberforce',
        image: wilberforce
    },
    {
        name: 'kingston',
        image: kingston
    },
    {
        name: 'holland',
        image: holland
    },
    {
        name: 'nursery',
        image: nursery
    },
    {
        name: 'hillingdon',
        image: hillingdon
    },
    {
        name: 'glasgow',
        image: glasgow
    }
];
