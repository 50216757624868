import Goline from './Goline';
import Promo from './Promo';
import MainBlock from './MainBlock';
import Partners from './Parnters';
import { withTranslation } from 'react-i18next';

function HomePage() {
    return (
        <>
            <Promo />
            <Goline />
            <MainBlock />
            <Partners />
        </>
    )
}

export default withTranslation()(HomePage);