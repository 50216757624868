import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';
import NewsLines from './NewsLines';

function MainBlock() {
    return (
        <>
            <Row className='mainBlock'>
                <Col md={8}>
                    <div>
                        <h2 className='mainHeader' style={{ textAlign: 'center' }}>{t('welcome message')}</h2>
                    </div>
                    <div className='mainBlockText'>
                        <p>{t('supplementary community')}</p>
                        <p>{t('proud of our school')}</p>
                        <p>{t('largest school')}</p>
                        <p>{t('since the start of the war')}</p>
                        <p>{t('happy and caring school')}</p>
                        <NavLink to="/contact-us">{t('feel free to contact')}</NavLink>
                    </div>
                </Col>
                <Col md={4}>
                    <NewsLines></NewsLines>
                </Col>
            </Row>
        </>
    );
}

export default withTranslation()(MainBlock);